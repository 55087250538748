export const LINKS = [
  // {
  //   text: 'management',
  //   id: 'management',
  //   href: '/management',
  // },
  {
    text: 'home',
    id: 'home',
    href: '/',
  },
  {
    text: 'yachts',
    id: 'yachts',
    href: null,
  },
  {
    text: 'Experiences',
    id: 'experience',
    href: null,
  },
  // {
  //   text: 'destinations',
  //   id: 'destinations',
  //   href: null,
  // },
  // {
  //   text: 'Food',
  //   id: 'food',
  //   href: null,
  // },
  // {
  //   text: 'toys',
  //   id: 'toys',
  //   href: null,
  // },
  {
    text: 'about',
    id: 'about',
    href: '/about',
  },
  {
    text: 'contact',
    id: 'contact',
    href: '/contact',
  },
]
