import React from 'react'

const FocusDestinationPage = () => {
  return (
    <div>
      <h1>FocusDestinationPage</h1>
    </div>
  )
}

export default FocusDestinationPage
